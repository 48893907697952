<template lang="pug">
  .xmas-signup
    .container.p-0
      XmasHeader
        div(style="text-shadow: 2px 2px 0 white;")
          .text-xxl.title
            | Der große
            br
            | touriDat Adventskalender 2024!
          .text-xl.text-uppercase.my-3
            | Erleben sie mit uns noch
            br
            | mehr vorfreude auf die schönste zeit des jahres!
          .text-lg
            | Lassen Sie sich jeden Tag aufs Neue
            br
            | von unseren Reisegewinnen verzaubern! Jetzt anmelden, gewinnen und den Reisegutschein ganz bequem einlösen!
        .my-3
          a.button.button-primary.button-tdays(href="#signup" v-if="nextDrawAt")
            .text-lg zur Anmeldung
          .text-center.d-md-none
            button.no-style.text-underline.txt-td-grey-darker(@click="showInfoModal") So Funktioniert's
      .px-4
        section.highlights.mt-2
          .highlight.d-block.d-md-flex(v-for="highlight in highlights")
            .image.aspect-square.bg-page.w-100(style="max-width:145px;")
              .img.w-100.h-100(:style="`background-image:url('${highlight.imageUrl}')`")
            .p-2.textbox.d-flex.align-items-center
              div
                .text-lg.font-weight-semibold.w-100.mb-2(style="text-transform:uppercase;") {{highlight.title}}
                .text-md.w-100 {{highlight.text}}
        section.doors(:style="`margin-top:6.25rem;${showSignUp ? '' : 'margin-bottom:6.25rem;'}`")
          PrizeDoor(v-for="n in 24" :number="n" :current-day="currentDay")
        section#signup.position-relative(v-if="showSignUp")
          .my-4(:style="`min-height: 5.1rem;transition:opacity 0.2s;opacity:${showSignupHints && (!hasValidEmail || !acceptedTerms) ? 1 : 0};`")
            .d-flex.justify-content-end
              .hints.txt-white.p-3.position-relative(style="border-radius:10px;background-color: #7a1313;")
                ul.mb-0.pl-3
                  li(v-if="!hasValidEmail") Bitte geben Sie eine gültige E-Mail-Adresse an
                  li(v-if="!acceptedTerms") Zur Anmeldung wird Ihre Zustimmung der Datenschutz- und Teilnahmebedingungen benötigt
          form
            .signup-box.d-md-flex.align-items-stretch.justify-content-between.p-2.bg-tdays-lighter
              input.w-100.text-xl.font-weight-normal(type="email" v-model="email" style="padding:1.5rem;" placeholder="E-Mail-Adresse" @focus="showSignupHints = true")
              button.w-100.w-md-auto.button.button-primary.button-tdays.text-xl(@click="triggerSignup" :disabled="!canSignUp") teilnehmen
            trustcaptcha-component#trustcaptchaComponent.mt-2.mb-2(
              ref="trustcaptchaComponent"
              :sitekey="this.tpSitekey"
              :license="tpLicense"
              width="full"
              language="de"
              theme="media"
              v-on:captchaSolved="solved($event.detail)"
              v-on:captchaFailed="failed($event.detail)")
          .terms
            ScalableCheckbox.cursor-pointer.bg-tdays-lighter.txt-white.d-inline-block(v-model="acceptedTerms" style="font-size:140%; margin-bottom:-8px; margin-top:5px; margin-right:5px;")
            span.user-select-none(style="opacity:0.75")
              | Hiermit bestätige ich, die&nbsp;
              router-link.text-underline(:to="{name: 'data-protection'}" target="_blank") Datenschutzbestimmungen
              | &nbsp;sowie die&nbsp;
              router-link.text-underline(:to="{name: 'ContestConditionsSite'}" target="_blank") Teilnahmebedingungen
              | &nbsp;gelesen und akzeptiert zu haben. Als Teilnehmer des Gewinnspiels von touriDat willige ich ein, dass meine E-Mail-Adresse zur Zusendung von Produktempfehlungen per E-Mail verwendet wird. Ich kann mich jederzeit über einen Link in der E-Mail von diesen Empfehlungen abmelden.
        div(style="margin-bottom:3.125rem;margin-top:3.125rem;" v-if="nextDrawAt")
          .text-center.text-xl NÄCHSTE VERLOSUNG IN:
          .d-flex.justify-content-center
            .deadline-container
              flip-countdown.deadline(:deadline="nextDrawAt" color="#fff" background-color="#488345" :format="['h','m','s']")
        .text-center.my-4.py-4(v-if="hasAnyWinners")
          router-link.button.button-primary.button-tdays.w-100(:to="{name:'AdventCalendarWinners'}")
            .text-xl zu den Gewinnern
        section
          .txt-white.mb-4.py-1(style="background-color: rgba(26, 78, 23, 0.6);backdrop-filter:blur(3px);")
            ul.star-list
              li.my-4 Nehmen Sie jetzt kostenlos teil und nutzen Sie Ihre Chance, einen von 300 Preisen im Gesamtwert von über 45.000 EUR zu gewinnen! - Einfach E-Mail-Adresse eintragen und Ihre Losnummer per E-Mail erhalten!
              li.my-4
                | Alle Gewinn-Losnummern werden am Vormittag des Folgetages hier, auf&nbsp;
                a.text-white.text-underline(href="https://www.facebook.com/touriDat") Facebook
                | &nbsp;und&nbsp;
                a.text-white.text-underline(href="https://www.instagram.com/touridat/") Instagram
                | &nbsp;bekannt gegeben. Zusätzlich werden die Gewinner per E-Mail benachrichtigt!
              li.my-4 Nehmen Sie Ihr Glück selbst in die Hand und erhöhen Sie Ihre Gewinnchance - jetzt einfach anmelden und den angegebenen Link teilen!
          .txt-white.py-1(style="background-color: rgba(26, 78, 23, 0.6);backdrop-filter:blur(3px);")
            ul.star-list
              li.my-4 Jeden Tag werden zwei touriDat-Reisen und 10 Wertgutscheine à 25,00€ verlost!
              li.my-4 An Nikolaus warten nicht nur zwei ganz besondere Reiseerlebnisse auf Sie sondern zusätzlich vier Wertgutscheine à 200€ sowie 15 Wertgutscheine à 25,00€!
              li.my-4 Zum krönenden Abschluss verlosen wir an Heiligabend passend zum Fest, vier zauberhafte touriDat-Reisen, fünf Luxusreisen im 4*, 4*S oder 5*Hotel und 15 Wertgutscheine à 25,00€!
          span(style="opacity: 0.75;") Aktionszeitraum: 01.12 - 24.12.2024. Das Gewinnspiel endet mit der finalen Verlosung an Heiligabend um 9.00 Uhr. Bei einer Inanspruchnahme eines Gewinns können Kosten oder Folgekosten zB. in Form einer Eigenanreise entstehen.
        footer.text-center.mt-4.pt-4
          .text-xl Viel Glück und eine besinnliche Weihnachtszeit wünscht Ihnen:
          .position-relative.d-inline-block(style="margin:0 auto;")
            img.img-fluid.w-100.my-4.pt-4(src="https://s3-cloud.td-cdn.de/Images/Mail/greetings.png" style="max-width:418px;")
            .socials.d-flex.align-items-center.justify-content-between.text-xxl.mt-4
              SocialLinkInstagram.social-link
              SocialLinkFacebook.social-link
              SocialLinkWhatsapp.social-link
</template>
<script>
import moment from "moment";
import FlipCountdown from "@/views/components/partials/countdown/FlipCountdown.vue";
import XmasHeader from "@/views/static/adventCalendar/partials/XmasHeader.vue";
import DiscountVoucherCategory from "@/views/category/DiscountVoucherCategory.vue";
import MailValidator from "@/lib/helper/mail_validator.ts";
import PrizeDoor from "@/views/static/adventCalendar/partials/PrizeDoor.vue";
import SocialLinkInstagram from "@/views/components/partials/buttons/socials/SocialLinkInstagram.vue";
import SocialLinkFacebook from "@/views/components/partials/buttons/socials/SocialLinkFacebook.vue";
import SocialLinkWhatsapp from "@/views/components/partials/buttons/socials/SocialLinkWhatsapp.vue";
import ScalableCheckbox from "@/views/components/partials/inputs/ScalableCheckbox.vue";
import config from "@/config/config";

const DRAW_AT_HOUR = 9;
const isDev = process?.env?.NODE_ENV !== "production";

export default {
  name: "AdventCalendarSignup",
  components: {
    ScalableCheckbox,
    SocialLinkWhatsapp,
    SocialLinkFacebook,
    SocialLinkInstagram,
    PrizeDoor,
    DiscountVoucherCategory,
    XmasHeader,
    FlipCountdown
  },
  inject: ["signup", "showInfoModal"],
  props: {
    contest: {
      type: Object,
      required: true
    }
  },
  setup() {
    const highlights = [
      // liste der preise im oberen bereich der seite
      {
        imageUrl:
          "https://s3-cloud.td-cdn.de/Images/xmas-2024/xmas_24_whirlpool.jpg",
        title: "5x eine Traumhafte Luxusreise Ab 4*",
        text: "Als Hauptpreis verlosen wir an Heiligabend in diesem Jahr 5 exquisite Deluxe-Reisen zu zweit in erlesenen First-Class-Hotels mit mindestens 4*, teilweise 4*S und einem edlen 5* Haus. Jede für sich eine einmalige Auszeit in purem Luxus."
      },
      {
        imageUrl:
          "https://s3-cloud.td-cdn.de/Images/xmas-2023/mood1.jpg",
        title: "50 Unvergessliche Reiseerlebnisse",
        text: "Traumhafte Tage an der See, aufregende Aktiv- & Wanderurlaube, einzigartige Wellnessauszeiten oder sehenswerte Erkundungstouren durch historische Städtchen. Wir verlosen täglich zwei dieser abwechslungsreichen Urlaubsangebote."
      },
      {
        imageUrl:
          "https://s3-cloud.td-cdn.de/Images/moloss/shipping/stone-envelope-merry-christmas.jpg",
        title: "4x Ein Fantastischer Gutschein im wert von 200€",
        text: "Gewinnen Sie einen von vier großartigen Wertgutscheinen mit denen Sie die freie Wahl haben, wohin die nächste Reise gehen soll. Wählen Sie dabei aus einer umfangreichen Auswahl an verschiedenen Reise- und Urlaubszielen."
      },
      {
        imageUrl:
          "https://td-cloud.s3.eu-central-1.amazonaws.com/Images/xmas-2024/xmas_24_wertgutscheine.jpg",
        title: "250 touriDat Wertgutscheine",
        text: "Neben den großartigen Reisen aus unserem vielfältigen touriDat-Portfolio haben Sie täglich die Chance auf einen von mindestens 10 touriDat-Wertgutscheinen im Wert von jeweils 25€."
      }
    ];
    return { highlights };
  },
  data() {
    return {
      email: "",
      acceptedTerms: false,
      showSignupHints: false,
      tpSitekey: config.external.trustcaptcha.sitekey,
      tpLicense: config.external.trustcaptcha.license,
      verificationToken: null,
    };
  },
  computed: {
    hasAnyWinners() {
      return !!this.contest?.MarketingPromoContestPrizes?.filter((prize) => !!prize.PrizeDrawnAt)?.length;
    },
    currentDay() {
      //if (isDev) return 10;
      const now = new Date();
      if (moment(now).isBefore(this.contest.PrizeEnabledFrom)) return 0;
      if (moment(now).isAfter(this.contest.PrizeEnabledTill)) return 26;
      return now.getDate();
    },
    showSignUp() {
      const now = new Date();
      const registrationFrom = new Date(isDev ? "2024-01-01" : this.contest.RegistrationFrom);
      const registrationTill = new Date(isDev ? "2024-12-31" : this.contest.RegistrationTill);
      return now > registrationFrom && now < registrationTill;
    },
    nextDrawAt() {
      const now = new Date();
      const prizeEnabledFrom = new Date(isDev ? "2024-01-01" : this.contest.PrizeEnabledFrom);
      const prizeEnabledTill = new Date(isDev ? "2024-12-31" : this.contest.PrizeEnabledTill);

      if (now < prizeEnabledFrom || now > prizeEnabledTill) return null;
      if (now.getHours() < DRAW_AT_HOUR) {
        return moment().set("hour", DRAW_AT_HOUR).set("minute", 0).set("second", 0).format("YYYY-MM-DD HH:mm:ss");
      }
      if (this.currentDay > 24) return null;
      return moment().add(1, "day").set("hour", DRAW_AT_HOUR).set("minute", 0).set("second", 0).format("YYYY-MM-DD HH:mm:ss");
    },
    hasValidEmail() {
      const email = this.email?.trim()?.toLowerCase();
      return MailValidator.validate(email);
    },
    canSignUp() {
      return this.hasValidEmail && this.acceptedTerms && this.verificationToken;
    },
    /**
     * @returns {Record<string,string[]>}
     */
    prizes() {
      const prizes = {};
      this.contest.MarketingPromoContestPrizes.forEach((prize) => {
        const drawAt = new Date(prize.DrawPrizeAt);
        const day = drawAt.getDate();
        if (!prizes[day]) prizes[day] = [];
        prizes[day].push(prize.id);
      });
      return prizes;
    }
  },
  methods: {
    resetForm(){
      this.$refs.trustcaptchaComponent.reset();
      this.email = "";
      this.acceptedTerms = false;
    },
    solved(verificationToken) {
      //console.log(`Verification-token: ${verificationToken}`);
      this.verificationToken = verificationToken;
    },
    failed(error) {
      console.error(error.message);
    },
    async triggerSignup() {
      this.showSignupHints = false;
      const email = this.email.trim().toLowerCase();
      return await this.signup(email, this.verificationToken);
    }
  },
  created() {
  },
  destroyed() {
  }
};
</script>
<style lang="scss" scoped>
@import "@/assets/styles/colors";
@import "@/assets/styles/mediaqueries";

.social-link {
  transition: all 0.2s;

  &:hover {
    color: $tdays-lighter;
    transform: scale(1.1);
  }
}

.hints {
  &:before {
    content: "";
    position: absolute;
    background-color: inherit;
    width: 1.5rem;
    aspect-ratio: 1;
    bottom: 0;
    transform: rotate(45deg) translateY(50%);
    right: 0.5rem;
  }
}

.w-md-auto {
  @include md {
    width: auto !important;
  }
}

.title {
  max-width: initial;
  @include lg {
    max-width: 225px;
  }
}

.doors {
  display: grid;
  gap: 1rem;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  @include sm {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }
  @include md {
    grid-template-columns: repeat(6, minmax(0, 1fr));
  }
}

.highlights {
  margin-top: -2.25rem;
  margin-bottom: -2.25rem;
}

.highlight {
  margin-top: 2.25rem;
  margin-bottom: 2.25rem;

  .image {
    margin: 0 auto;
    margin-bottom: -1.75rem;
    position: relative;
    z-index: 1;
    border-top-left-radius: 50%;
    border-bottom-left-radius: 50%;
    border-top-right-radius: 50%;
    border-bottom-right-radius: 50%;

    .img {
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center center;
      border-radius: 50%;
    }

    @include md {
      margin-bottom: 0;
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }
  }

  .textbox {
    padding-top: 2rem !important;
    text-align: center;
    @include md {
      padding-top: 0.5rem !important;
      text-align: left;
    }
  }

  .textbox, .image {
    color: $white;
    backdrop-filter: blur(3px);
    background-color: rgba(26, 78, 23, 1);
  }

  &:first-child .textbox,
  &:first-child .image {
    background-color: $tpics-basic;
  }

  &:nth-child(3) .textbox,
  &:nth-child(3) .image {
    background-color: rgba(26, 78, 23, 0.8);
  }

  &:nth-child(4) .textbox,
  &:nth-child(4) .image {
    background-color: rgba(26, 78, 23, 0.6);
  }
}

.deadline-container {
  font-size: 1.75rem;
  --margin: 1.5rem;
  margin-top: var(--margin);
  margin-bottom: var(--margin);

  .deadline {
    --counter-color: #{$tdays-lighter};
    color: $white;
  }
}
</style>

import { MarketingPromoContest } from "@/graphql/MarketingPromoContest.ts";
import { ApolloError } from "apollo-client";

/**
 * @typeDef {{
 *   id: string;
 *   Ticket: string;
 *   MarketingPromoParticipantReferrerId: string;
 * }} ReturnType
 */
/**
 * @param { unknown & {mutate:({mutation:unknown,variables?:unknown}) => Promise<{data:{signupForContest:ReturnType}}>} } client an instance of ApolloClient
 * @param {{
 *  contestId: string | number;
 *  source?: string;
 *  referrerGuid?: string;
 *  email: string;
 * }} variables Variables to be sent for the SignupForContest mutation
 * @returns {Promise<ReturnType>}
 */
export const signup = async (client, variables) => {
  try {
    return await client.mutate({
      mutation: MarketingPromoContest.Mutations.SignupForContest,
      variables: {
        ...variables,
      }
    }).then((response) => response?.data?.signupForContest);
  } catch (e) {
    console.error(e);
    if (e instanceof ApolloError) {
      const [err] = e.graphQLErrors;
      if (err?.extensions?.code === "ERR_PARTICIPANT_ALREADY_SIGNED_UP") {
        const ticket = err?.extensions?.ticket;
        throw new Error(`Da Sie bereits angemeldet waren, haben wir Ihnen nochmals eine E-Mail zugeschickt. ${ticket?.length ? `Ihre Losnummer lautet: ${ticket}.` : ""}`);
      }
    }
    throw new Error("Ein unbekannter Fehler ist aufgetreten");
  }
};

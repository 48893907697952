<template lang="pug">
  .timeline
    .timeline-slide.row(v-for="(slide,i) in slides")
      .col-12.col-md-6.pr-md-0
        TimelineSlide.my-4(:year="slide.year" :text="slide.text" orientation="left" v-if="!isEven(i)")
      .col-12.col-md-6.pl-md-0
        TimelineSlide.my-4(:year="slide.year" :text="slide.text" orientation="right" v-if="isEven(i)")

</template>
<script>

import { clamp } from "@/lib/helper/math";
import TimelineSlide from "@/views/static/anniversary/partials/timeline/TimelineSlide.vue";

export default {
  name: "TimelineComponent",
  components: { TimelineSlide },
  props: {
    slides: {
      type: Array,
      required: true,
      validator(value) {
        return value.every((entry) => ["year", "text"].every((key) => key in entry));
      }
    }
  },
  computed: {
    breakPoints() {
      return this.slides.map((slide) => slide.year);
    },
    numberOfSlides() {
      return this.slides.length;
    },
    currentBreakPoint() {
      const index = Math.floor((this.slideValue / 100) * (this.numberOfSlides));
      return this.breakPoints[clamp(index, 0, this.numberOfSlides - 1 ? this.numberOfSlides - 1 : 1)];
    },
    translatedSlideValue() {
      const mod = 100 / this.numberOfSlides;
      return (100 - mod) * (this.slideValue / 100);
    }
  },
  data() {
    return {
      slideValue: 0
    };
  },
  methods: {
    isEven(num) {
      return num % 2 !== 0;
    },
    stopScroll() {
      document.querySelector("body").classList.add("no-scroll");
    },
    resetScroll() {
      document.querySelector("body").classList.remove("no-scroll");
    }
  }
};
</script>
<style scoped lang="scss">
@import "@/assets/styles/mediaqueries";

.timeline {
  background-image: url("@/assets/images/dotted-line.svg");
  background-position-x: -42px;
  background-position-y: center;
  background-repeat: repeat-y;
  background-size: 85px;

  @include md {
    background-position-x: 50%;
  }
}
</style>

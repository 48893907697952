<template lang="pug">
  section.anniversary-signup.position-relative.container
    .text-center
      .h2.signup-headline.mb-4 Jetzt einfach E-Mail-Adresse eintragen und jeden Monat an unserer Verlosung teilnehmen.
    .position-relative
      .hints-wrap.position-absolute(:style="`transition:opacity 0.2s;${showSignupHints && (!hasValidEmail || !acceptedTerms) ? 'opacity:1;' : 'opacity:0;pointer-events:none'}`")
        .hints.txt-white.p-3.mb-2
          ul.mb-0.pl-3
            li(v-if="!hasValidEmail") Bitte geben Sie eine gültige E-Mail-Adresse an
            li(v-if="!acceptedTerms") Zur Anmeldung wird Ihre Zustimmung der Datenschutz- und Teilnahmebedingungen benötigt
    form
      .signup-box.d-md-flex.align-items-stretch.justify-content-between.p-2.bg-anniversary-dark
        input.w-100.text-xl.font-weight-normal.bg-td-grey-darker.text-white(type="email" v-model="email" style="border:0;padding:1.5rem;" placeholder="E-Mail-Adresse" @focus="showSignupHints = true" @blur="showSignupHints = false")
        button.w-100.w-md-auto.button.button-primary.button-anniversary.text-xl(@click="triggerSignup" :disabled="!canSignUp" type="button") teilnehmen
      trustcaptcha-component#trustcaptchaComponent.mt-2.mb-2(
        ref="trustcaptchaComponent"
        :sitekey="this.tpSitekey"
        :license="tpLicense"
        width="full"
        language="de"
        theme="media"
        v-on:captchaSolved="solved($event.detail)"
        v-on:captchaFailed="failed($event.detail)")
    .terms
      ScalableCheckbox.cursor-pointer.bg-anniversary-basic.txt-white.d-inline-block(v-model="acceptedTerms" style="font-size:140%; margin-bottom:-8px; margin-top:5px; margin-right:5px;")
      span.user-select-none.small(style="opacity:0.75")
        | Hiermit bestätige ich, die&nbsp;
        router-link.text-underline.text-anniversary-basic(:to="{name: 'data-protection'}" target="_blank") Datenschutzbestimmungen
        | &nbsp;sowie die&nbsp;
        router-link.text-underline.text-anniversary-basic(:to="{name: 'ContestConditionsSite'}" target="_blank") Teilnahmebedingungen
        | &nbsp;gelesen und akzeptiert zu haben. Als Teilnehmer des Gewinnspiels von touriDat willige ich ein, dass meine E-Mail-Adresse zur Zusendung von Produktempfehlungen per E-Mail verwendet wird. Ich kann mich jederzeit über einen Link in der E-Mail von diesen Empfehlungen abmelden.
    .text-center.mt-4.pt-4.share-text(v-if="showsShareText")
      span Geteiltes Glück ist doppeltes Glück - Teilen Sie den Gewinnspiel-Link mit Ihren Liebsten: Jede Empfehlung bringt Ihnen ein zusätzliches Los und steigert Ihre Gewinnchance. Jetzt anmelden und Link teilen!
</template>
<script>
import ScalableCheckbox from "@/views/components/partials/inputs/ScalableCheckbox.vue";
import MailValidator from "@/lib/helper/mail_validator.ts";
import { signup } from "@/lib/contest/signup";
import config from "@/config/config";

export default {
  name: "AnniversarySignupSection",
  components: { ScalableCheckbox },
  emits: ["done", "startSignup"],
  setup() {
  },
  props: {
    showsShareText: {
      type: Boolean,
      default: true
    },
    contest: {
      type: Object,
      required: true
    },
    session: {
      type: Object,
      validator: (sess) => ["get", "set", "clear"].every((key) => typeof sess[key] === "function")
    }
  },
  data() {
    return {
      email: "",
      acceptedTerms: false,
      showSignupHints: false,
      tpSitekey: config.external.trustcaptcha.sitekey,
      tpLicense: config.external.trustcaptcha.license,
      verificationToken: null,
    };
  },
  computed: {
    hasValidEmail() {
      const email = this.email?.trim()?.toLowerCase();
      return MailValidator.validate(email);
    },
    canSignUp() {
      return this.hasValidEmail && this.acceptedTerms && this.verificationToken;
    }
  },
  methods: {
    resetForm(){
      this.$refs.trustcaptchaComponent.reset();
      this.email = "";
      this.acceptedTerms = false;
    },
    solved(verificationToken) {
      //console.log(`Verification-token: ${verificationToken}`);
      this.verificationToken = verificationToken;
    },
    failed(error) {
      console.error(error.message);
    },
    async triggerSignup() {
      this.$emit("startSignup");
      try {
        this.showSignupHints = false;
        const email = this.email.trim().toLowerCase();
        const result = await signup(this.$apollo, {
          contestId: this.contest.id,
          source: this.session?.get("source"),
          referrerGuid: this.session?.get("referrerGuid"),
          email,
          token: this.verificationToken
        });
        const msg = `Sie haben sich erfolgreich angemeldet. ${result?.Ticket?.length ? `Ihre Losnummer lautet: ${result.Ticket}.` : ""}`;
        this.$alert(msg, "Erfolg", "success");
      } catch (e) {
        await this.$alert(e.message);
      } finally {
        this.resetForm();
        this.$emit("done");
      }
    }
  },
  created() {
  },
  destroyed() {

  },
  unmounted() {

  }
};
</script>
<style scoped lang="scss">
@import "@/assets/styles/anniversary-colors";
@import "@/assets/styles/mediaqueries";

.w-md-auto {
  @include md {
    width: auto !important;
  }
}

.text-anniversary-light {
  color: $color-anniversary-light;
}

.text-anniversary-basic {
  color: $color-anniversary-basic;
}

.text-anniversary-dark {
  color: $color-anniversary-dark;
}

.text-anniversary-darker {
  color: $color-anniversary-darker;
}

.bg-anniversary-light {
  background-color: $color-anniversary-light;
}

.bg-anniversary-basic {
  background-color: $color-anniversary-basic;
}

.bg-anniversary-dark {
  background-color: $color-anniversary-dark;

  &.box {
    border-left: 4px solid $color-anniversary-light;
  }
}

.bg-anniversary-darker {
  background-color: $color-anniversary-darker;
}

@mixin td-button-bg-effect($bgcolor, $bg-image-color-1, $bg-image-color-2) {
  background: $bgcolor;
  background-image: linear-gradient(
      to right,
      $bg-image-color-1 50%,
      $bg-image-color-2 50%
  );
  background-size: 220% 100%;
  background-position: bottom left;
  &:hover {
    background-position: bottom right;
  }
}

.text-xl {
  font-size: 1.5rem;
  @include md {
    font-size: 1.75rem;
  }
  @include lg {
    font-size: 1.875rem;
  }
}

.hints-wrap {
  top: 0;
  left: 0;
  width: max-content;
  max-width: 100%;
  transform: translate(0, -100%);

  .hints {
    border-radius: 10px;
    background-color: rgba(122, 19, 19, 0.75);
    backdrop-filter: blur(5px);
  }

}
</style>

<template lang="pug">
  router-link.d-block.anniversary-host-header.bg-td-grey-basic.mb-4.position-relative(to="/30-jahre-jubilaeum" style="text-decoration:none")
    .d-flex.align-items-center
      .icon.aspect-square.d-flex.align-items-center
        img.m-3(src="https://s3-cloud.td-cdn.de/Images/Promo/30-Jahre-TD/dreissig.png" alt="30 Jahre touriDat" style="max-width:30px;")
      .text.w-100.ml-4
        .banner-text Wir feiern 30 Jahre touriDat!
      button.button.button-secondary.button-anniversary.mr-2 mehr erfahren
    //.d-flex.align-items-center.justify-content-between
      .bg-td-grey-darker.w-100.position-relative.aspect-square.d-flex.align-items-center.justify-content-center.icon-30(style="max-width:62px;")
        img.img-fluid.w-100.m-3(src="https://s3-cloud.td-cdn.de/Images/Promo/30-Jahre-TD/dreissig.png")
      .w-100.text.px-3.h2.m-0 Wir feiern 30 Jahre touriDat!
      div.text-center
        button.button.button-secondary.button-anniversary.mr-2 mehr erfahren
</template>
<script>
export default {
  name: "AnniversaryHostHeader"
};
</script>
<style scoped lang="scss">
@import "@/assets/styles/anniversary-colors";
@import "@/assets/styles/colors";
@import "@/assets/styles/mediaqueries";

.anniversary-host-header {
  color: $color-anniversary-light;
  user-select: none;
  cursor: pointer;

  .icon {
    position: relative;
    background-color: $td-grey-darker;

    &::after {
      content: "";
      background-color: inherit;
      position: absolute;
      right: 0;
      top: 50%;
      width: 15px;
      aspect-ratio: 1;
      transform: translate(50%, -50%) rotate(45deg);
    }
  }

  &::after {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: $td-grey-darker;
    opacity: 0.3;
    z-index: -1;
  }

  .button {
    font-size: 12px;
    padding: 10px 15px;
    @include sm {
      font-size: 16px;
      padding: 13px 30px;
    }
  }
}

.banner-text {
  font-size: 12px;
  @include md {
    font-size: 16px;
  }
  @include sm {
    font-size: 22px;
  }
}
</style>

import { WebTemplatesEnum } from "@/utils/WebTemplatesEnum.ts";

export default [
  {
    type: WebTemplatesEnum.Default,
    templateClass: "tdays"
  },
  {
    type: WebTemplatesEnum.Golf,
    templateClass: "tgolf"
  },
  {
    type: WebTemplatesEnum.Tipps,
    templateClass: "ttipps"
  },
  {
    type: WebTemplatesEnum.Blog,
    templateClass: "tblog"
  },
  {
    type: WebTemplatesEnum.Deluxe,
    templateClass: "tdeluxe"
  },
  {
    type: WebTemplatesEnum.Map,
    templateClass: "tmaps"
  },
  {
    type: WebTemplatesEnum.Anniversary,
    templateClass: "anniversary"
  }
];

const CONTEST_SESSION_KEY = "molossContestSession";
const saveContestSession = (data) => {
  if (typeof data !== "object" || !Object.keys(data).length) {
    sessionStorage.removeItem(CONTEST_SESSION_KEY);
    return;
  }
  sessionStorage.setItem(CONTEST_SESSION_KEY, JSON.stringify(data));
};
const loadContestSession = () => {
  try {
    return JSON.parse(sessionStorage.getItem(CONTEST_SESSION_KEY) || "{}");
  } catch (e) {
    return {};
  }
};
/**
 * @type {{
 *   set:(key:string,value:any) => void;
 *   get:(key:string) => any | undefined;
 *   clear:() => void;
 * }}
 */
export const contestSession = {
  set(key, value) {
    const session = loadContestSession();
    session[key] = value;
    saveContestSession(session);
  },
  get(key) {
    const session = loadContestSession();
    return session[key];
  },
  clear() {
    sessionStorage.removeItem(CONTEST_SESSION_KEY);
  }
};

<template lang="pug">
  header.anniversary-event-header.d-flex.align-items-center.justify-content-center.text-anniversary-light.position-relative
    .text-center
      .pre-title wir feiern
      .w-100.year-digit
        img.img-fluid.w-100.px-4(src="https://s3-cloud.td-cdn.de/Images/Promo/30-Jahre-TD/dreissig.png")
      div
        span.large-headline Jahre touriDat
</template>
<script>
export default {
  name: "AnniversaryEventHeader"
};
</script>
<style scoped lang="scss">
@import "@/assets/styles/mediaqueries";
@import "@/assets/styles/anniversary-colors";

.pre-title {
  font-size: 2rem;
  @include md {
    font-size: 2.5rem;
  }
}

.large-headline,
.pre-title {
  line-height: 1;
  color: $color-anniversary-light;
}

.large-headline {
  font-size: 35px;
  @include md {
    font-size: 60px;
  }
  @include lg {
    font-size: 85px;
  }
}

.year-digit {
  margin: 0 auto;
  max-width: 260px;

  img {
    //min-width: 300px;
    padding: 0 4rem;
  }

  @include md {
    max-width: 390px;
  }
  @include lg {
    max-width: 410px;
  }
  //max-width:
}
</style>

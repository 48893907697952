<template lang="pug">
  .timeline-slide(:class="`orientation-${orientation}`")
    .d-flex.align-items-center.justify-content-between.position-relative(style="gap:0.5rem;")
      .point.left.rounded-circle.bg-themed-basic.aspect-square(style="width:25px;")
      .p-4.w-100.bg-td-grey-dark
        .h1.text-center {{year}}
        .hr.bg-themed-basic.my-2(style="height:1px")
        div {{text}}
      .point.right.rounded-circle.bg-themed-basic.aspect-square(style="width:25px;")
</template>
<script>
export default {
  name: "TimelineSlide",
  props: {
    year: { type: [Number, String], required: true },
    text: { type: String, required: true },
    orientation: { type: String, default: "left", validator: (value) => ["left", "right"].includes(value) }
  }
};
</script>
<style scoped lang="scss">
@import "@/assets/styles/mediaqueries";

.timeline-slide {
  .point {
    position: relative;
    --thickness: 8px;

    &:after {
      content: "";
      position: absolute;
      left: 50%;
      top: 50%;
      width: calc(100% - var(--thickness));
      aspect-ratio: 1;
      border-radius: 50%;
      transform: translate(-50%, -50%);
      background-color: #222;
      z-index: 1;
    }
  }

  .point.left {
    left: 0;
    transform: translateX(-50%);
    display: block;
  }

  .point.right {
    right: 0;
    transform: translateX(50%);
    display: none;
  }

  .orientation-left {
    @include md {
      .point.left {
        display: none;
      }
      .point.right {
        display: block;
      }
    }
  }

  .orientation-right {
    @include md {
      .point.left {
        display: block;
      }
      .point.right {
        display: none;
      }
    }
  }
}
</style>

<template lang="pug">
  .host-discount-voucher
    .container
      BuyersProtectionBox
    .container
      HostHeader.py-4(:title="host.Name" :sub-title="''")
        template(v-slot:afterTitle)
          HostFavoriteIcon(:host="host" v-if="host")
      .pb-4
        .voucher-hero.loader
          img.img-fluid.w-100(src="https://td-cloud.s3.eu-central-1.amazonaws.com/Images/makaira/td-voucher/discount-voucher-teaser-image-compressed.jpg")
      AnniversaryHostHeader
    HostSectionHeader.item-h1.mb-0.position-relative(style="z-index:1") Gutscheindetails
    .container.px-4.pb-4
      .row
        .col-12.col-lg-8
          .checklist.mt-4
            .d-flex.align-items-start.py-2(v-for="point in checklist")
              font-awesome-icon.txt-tdays-lighter.mr-1.mt-1(icon="fa-check")
              span {{point}}
        .col-12.col-lg-4
          HostViewSidebar.mt-4.mt-lg-0
            .bg-td-grey-lighter.pt-2
              .px-2.pt-2.mx-2.bg-white
                .txt-td-grey-basic.small Gewünschten Wert hier eintragen:
                .d-flex.align-items-center.justify-content-between
                  input.p-3.w-100.form-control.value-input(type="number" min="15" max="1000" :value="value" @change="changeValue")
                  .txt-td-grey-basic.h2.m-0.p-3.currency &euro;
              //.phone-button.pt-2.pb-lg-2.px-2
                TelephoneOrderButton.button.button-secondary.w-100(@click="showHotlineModal" button-class="themed") Telefonische Bestellung
              .px-2.pb-2
                .button.button-action.button-tdays.w-100.mt-2.mt-lg-0.add-to-shopping-basket(title="In den Warenkorb" @click="addToCart") in den Warenkorb
              //.tax-info.txt-td-grey-basic.small.text-right.w-100.bg-page * inkl. ges. geschuldete USt. + Versand
    hr
</template>

<script>
import HostSectionHeader from "@/views/components/host/layout/HostSectionHeader";
import HostHeader from "@/views/components/host/layout/HostHeader";
import BuyersProtectionBox from "@/views/components/partials/buyersProtection/BuyersProtectionBox";
import { showHotlineModal } from "@/lib/components/hotlineModal";
import HostViewSidebar from "@/views/components/host/layout/partials/HostViewSidebar";
import TelephoneOrderButton from "@/views/components/partials/buttons/telephoneOrder/TelephoneOrderButton";
import { clamp } from "@/lib/helper/math";
import EventBus from "@/event-bus";
import { Items } from "@/graphql/Items.ts";
import { toShoppingBasketItem } from "@/lib/plugins/shoppingBasket";
import HostFavoriteIcon from "@/views/host/partials/favoriteIcon/HostFavoriteIcon.vue";
import config from "@/config/config";
import AnniversaryHostHeader from "@/views/static/anniversary/partials/AnniversaryHostHeader.vue";
//import {sendAddToCart} from "@/lib/helper/gtm";

export default {
  name: "HostDiscountVoucherView",
  components: {
    AnniversaryHostHeader,
    HostFavoriteIcon,
    BuyersProtectionBox,
    HostHeader,
    HostSectionHeader,
    HostViewSidebar,
    TelephoneOrderButton
  },
  props: {
    host: {
      type: Object,
      required: true
    },
    itemId: String
  },
  setup() {
    const checklist = [
      "Wertgutscheine von touriDat gibt es in in Ihrem Wunschwert bis zu 1.000 EUR",
      "Die Wertgutscheine sind nicht an einen Mindestbestellwert gebunden, Sie haben freie Auswahl",
      "Einfache Einlösung: geben Sie einfach den Gutschein-Code in der Kaufabwicklung ein",
      "touriDat-Wertgutscheine sind ab Kaufdatum mindestens 3 Jahre gültig",
      "Sollte der Wert Ihres Gutscheins höher sein als die von Ihnen gewählte Reise bleibt Ihnen der Rest als Guthaben erhalten",
      "Sollte der Kaufpreis Ihrer Reise den Wert des Gutscheins übersteigen, können Sie die Differenz z. B. per Überweisung begleichen",
      "Wertgutscheine sind nicht stornierbar, nicht bar auszahlbar und von der Rabattierung durch Wertgutscheine ausgeschlossen."
    ];
    return { checklist };
  },
  data() {
    return {
      value: 15
    };
  },
  computed: {
    items() {
      return this.host?.Items || [];
    }
  },
  methods: {
    async loadVoucherItem(value) {
      return await this.$apollo
        .query({
          query: Items.Queries.DiscountVoucherItem,
          fetchPolicy: "no-cache",
          variables: { value }
        })
        .then((response) => response?.data?.discountVoucherItem);
    },
    async addToCart() {
      try {
        EventBus.$emit("changeLoadingState", true);
        const voucherItem = await this.loadVoucherItem(this.value);
        if (!voucherItem)
          throw new Error(
            `Could not find Discount Voucher for value ${this.value}`
          );

        const basketItem = {
          ...toShoppingBasketItem(voucherItem, false),
          HostUrl: voucherItem.Host.Url,
          IsDiscounted: false
        };
        await this.$shoppingBasket.addItem(basketItem);
      } catch (e) {
        this.$log.error(e);
      } finally {
        EventBus.$emit("changeLoadingState", false);
      }
    },
    changeValue(e) {
      if (!e?.target?.value) return;
      this.value = clamp(parseInt(e.target.value), 15, 1000);
    },
    showHotlineModal() {
      const modal = showHotlineModal.bind(this);
      modal({
        item: { Name1: this.host.Name, SystemItemId: "15776" }
      });
    }
  },
  metaInfo() {
    return {
      title: "Wertgutscheine verschenken",
      meta: [
        {
          name: "description",
          vmid: "description",
          content: "Wertgutscheine verschenken mit touriDat ✔ Mindestens 3 Jahre Gültigkeit ✔ Einfache Einlösung ✔ 100% Käuferschutz ✔ Jetzt bestellen!"
        },
        {
          name: "keywords",
          vmid: "keywords",
          content: ""
        },
        {
          vmid: "og:title",
          name: "og:title",
          content: "Wertgutscheine verschenken"
        },
        {
          vmid: "og:description",
          name: "og:description",
          content: "Wertgutscheine verschenken mit touriDat ✔ Mindestens 3 Jahre Gültigkeit ✔ Einfache Einlösung ✔ 100% Käuferschutz ✔ Jetzt bestellen!"
        }
      ],
      link: [
        {
          vmid: "canonical",
          rel: "canonical",
          href: `${config.server.host}/gastgeber/touridat-wertgutschein`
        }
      ]
    };
  }
};
</script>

<style lang="scss" scoped>
.voucher-hero {
  aspect-ratio: 2.65 / 1;
}
</style>

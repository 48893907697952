<template lang="pug">
  HostViewSidebar.host-item-menu
    template(v-slot:header)
      .fader.d-block.d-lg-none
      .td-logo(:class="items.length > 1 ? 'd-none d-lg-inline-block' : ''")
        LogoComponent.logo-component.bg-white
      button.button.button-primary.button-tdays.my-1.d-inline-block.d-lg-none.p-2.px-3(@click="showMenuModal" v-if="items.length > 1") Alle Angebote
    template(v-slot:default)
      .inner-menu.bg-td-grey-lighter
        .items.p-2.d-none.d-lg-block
          template(v-if="groupItems")
            details(v-for="group in itemGroups" open)
              summary.h2.m-0.py-3 {{group.label}}
              HostItemMenuItem.item.my-2(v-for="(item,index) in group.items" v-if="item" :custom-nights="customNights" :item="item" :has-tipps-discount="getHasTippsDiscount(item)" :has-tipps-discount-applied="tippsItems.includes(item.id)" @changeTipps="setTippsDiscount(item,$event)")
          template(v-else)
            HostItemMenuItem.item.my-2(v-for="(item,index) in items" v-if="item" :custom-nights="customNights" :item="item" :has-tipps-discount="getHasTippsDiscount(item)" :has-tipps-discount-applied="tippsItems.includes(item.id)" @changeTipps="setTippsDiscount(item,$event)")
              template(v-slot:customFeature="{item}")
                slot(name="customFeature" :item="item")
      .cta.bg-td-grey-lighter(style="border-radius:0 0 5px 5px")
        .p-2.d-none.d-lg-block
          hr.m-0
        .discount-info.text-center.px-2.d-none.d-lg-block(v-if="discountValue && showDiscountInfo")
          | Sie sparen aktuell&nbsp;
          b {{discountValue | formatPrice}}
          | &nbsp;bei Ihrem Einkauf!
        HostItemMenuItem.item.mobile-item.d-lg-none(v-if="selectedItem" :custom-nights="customNights" :indicate-active="false" :item="selectedItem" size="small" :has-tipps-discount="getHasTippsDiscount(selectedItem)" :has-tipps-discount-applied="tippsItems.includes(selectedItem.id)" @changeTipps="setTippsDiscount(selectedItem,$event)" @phoneOrder="showHotlineModal")
          template(v-slot:buttons)
            AddToShoppingBasketButton.button.button-primary.button-tdays.p-3.mr-1.pointer-events-all(size="small" title="In den Warenkorb" :item-id="selectedItemId" :is-tipps-item="tippsItems.includes(selectedItem.id)" :disabled="!isItemPurchasable(selectedItem)")
              font-awesome-icon(icon="shopping-cart")
            TelephoneOrderButton.button.button-primary.button-tdays.p-3.pointer-events-all(@click="showHotlineModal(selectedItem)" button-class="themed" :disabled="!isItemPurchasable(selectedItem)")
              font-awesome-icon.txt-white(icon="phone")
        .phone-button.pt-2.pb-lg-2.px-2.d-none.d-lg-block
          TelephoneOrderButton.button.button-secondary.w-100(@click="showHotlineModal(selectedItem)" button-class="themed" :disabled="!isItemPurchasable(selectedItem)") Telefonische Bestellung
        .px-2.pb-2.d-none.d-lg-block
          AddToShoppingBasketButton.button.button-action.button-tdays.w-100.mt-2.mt-lg-0(title="In den Warenkorb" :item-id="selectedItemId" :is-tipps-item="tippsItems.includes(selectedItem.id)" :disabled="!isItemPurchasable(selectedItem)") in den Warenkorb
      .tax-info.txt-td-grey-basic.small.text-right.w-100.bg-page * inkl. ges. geschuldete USt. + Versand

  //nav.host-item-menu


</template>

<script>
import {
  getCurrentTippsDiscounts,
  getDealQuantity,
  getItemAccommodation,
  getRegularQuantity,
  isItemInLiveShopping
} from "@/lib/helper/items";
import { availableCaterings } from "@/lib/helper/caterings";
import LogoComponent from "@/views/components/logo/LogoComponent";
import HostItemMenuItem from "@/views/components/host/layout/partials/HostItemMenuItem";
import { showHotlineModal } from "@/lib/components/hotlineModal";
import TelephoneOrderButton from "@/views/components/partials/buttons/telephoneOrder/TelephoneOrderButton";
import AddToShoppingBasketButton
  from "@/views/components/partials/buttons/addToShoppingBasket/AddToShoppingBasketButton";
import HostViewSidebar from "@/views/components/host/layout/partials/HostViewSidebar";
import MetaItem from "@/views/components/meta/MetaItem";
import SmallHostItem from "@/views/components/host/layout/partials/SmallHostItem.vue";
import ItemMenuModal from "@/views/components/modals/itemMenu/ItemMenuModal.vue";
import { sendViewItem } from "@/lib/helper/gtm";

export default {
  name: "HostItemMenu",
  components: {
    SmallHostItem,
    HostViewSidebar,
    HostItemMenuItem,
    LogoComponent,
    TelephoneOrderButton,
    AddToShoppingBasketButton,
    MetaItem
  },
  props: {
    host: {
      type: Object,
      required: true
    },
    items: {
      type: Array,
      required: true
    },
    selectedItemId: String,
    showAccommodations: {
      type: Boolean,
      default: true
    },
    showDiscountInfo: {
      type: Boolean,
      default: true
    },
    showItemFooter: {
      type: Boolean,
      default: true
    },
    groupItems: {
      type: Boolean,
      default: true
    },
    customNights: [Number, Array]
  },
  setup() {
    return { getItemAccommodation, isItemInLiveShopping };
  },
  data() {
    return {
      itemAvailabilities: [],
      tippsItems: []
    };
  },
  watch: {
    tippsItems(itemIds) {
      if (!itemIds?.length) {
        this.$session.remove("appliedTippsDiscounts");
        return;
      }
      this.$session.set("appliedTippsDiscounts", itemIds);
    }
  },
  computed: {
    discountValue() {
      if (!this.selectedItem) return;
      if (this.tippsItems.includes(this.selectedItem.id)) {
        if (!this.selectedItem?.CurrentPrice || !this.selectedItem?.LiveShoppingPrice) return;
        return this.selectedItem.RegularPrice - this.selectedItem.LiveShoppingPrice;
      }

      if (!this.selectedItem?.CurrentPrice || !this.selectedItem?.RegularPrice) return;
      return this.selectedItem.RegularPrice - this.selectedItem.CurrentPrice;
    },
    selectedItem() {
      return this.items.find((item) => item.id == this.selectedItemId);
    },
    itemGroups() {
      const groups = [];
      availableCaterings.forEach((catering) => {
        const items = this.items.filter((item) => {
          return item.Catering === catering.value;
        });
        if (!items?.length) return;
        let label;
        if (catering.value !== "ÜF") {
          label = `Angebote mit ${catering.description}`;
        } else {
          label = "Angebote mit Frühstück";
        }
        groups.push({ label, items });
      });

      return groups;
    },
    activeGroup() {
      return this.itemGroups.find((group) => {
        return group.items.find((item) => item.id == this.selectedItem.id);
      });
    }
  },
  methods: {
    setTippsDiscount(item, isApplied) {
      if (!item.id) return;
      if (isApplied) {
        if (this.tippsItems.includes(item.id)) return;
        this.tippsItems.push(item.id);
      } else {
        if (!this.tippsItems.includes(item.id)) return;
        this.tippsItems.splice(this.tippsItems.indexOf(item.id));
      }
      sendViewItem(item);
    },
    getHasTippsDiscount(item) {
      const showTipps = this.$route?.query?.tipps || false;
      return showTipps && item.IsTouriTippsItem;
    },
    isItemPurchasable(item) {
      if (!item?.id) return false;
      const isVoucherItem = [4, 6].includes(item.WebTemplateId);
      if (isVoucherItem) return true;
      const hasTippsDiscountApplied = this.tippsItems.includes(item.id);
      const regularQuantity = getRegularQuantity(item);
      const dealQuantity = getDealQuantity(item);
      //return hasTippsDiscountApplied || (item.IsInLiveShopping && dealQuantity)
      return hasTippsDiscountApplied || item.IsInLiveShopping ? dealQuantity > 0 : regularQuantity > 0;
    },
    showMenuModal() {
      const props = {
        itemGroups: this.itemGroups,
        activeGroup: this.activeGroup
      };
      this.$modal.show(ItemMenuModal, props, {
        height: "auto",
        classes: ["modalMaxHeight", "rounded-0", "item-menu-modal"]
      });
    },
    showHotlineModal(item) {
      if (!item) return;
      const modal = showHotlineModal.bind(this);
      modal({
        item
      });
    }
  },
  async mounted() {
    this.tippsItems = getCurrentTippsDiscounts(this.$session);
  }
};
</script>
<style lang="scss" scoped>
@import "@/assets/styles/_colors.scss";
@import "@/assets/styles/mediaqueries.scss";

.host-item-menu {
  position: relative;
  top: 0;

  .menu-header {
    background-color: rgba($td-grey-darker, 0.9);
    backdrop-filter: blur(2px);
  }

  .fader {
    width: 100%;
    height: 50px;
    position: absolute;
    pointer-events: none;
    background-image: linear-gradient(0deg, rgba(240, 240, 240, 1) 0%, rgba(240, 240, 240, 0) 100%);
    top: 0;
    transform: translate(0%, -100%);
  }

  .items {
    max-height: calc(100vh - 250px);
    overflow: hidden;
    overflow-y: auto;

    details {
      summary {
        list-style: none;
        position: relative;

        &:after {
          font-weight: bold;
          position: absolute;
          right: 0;
          top: 50%;
          transform: translate(0%, -50%);
          padding: 0 0.5rem;
        }
      }

      &:not([open]) {
        summary {
          &:after {
            content: "+";
          }
        }
      }

      &[open] {
        summary {
          &:after {
            content: "-";
          }
        }
      }
    }
  }

  @include lg {
    .phone-button {
      background-color: $td-grey-lighter;
    }

    .menu-header {
      background-color: $td-grey-darker;
      backdrop-filter: initial;
    }

    .items details {
      pointer-events: none;

      .item {
        pointer-events: all;
      }

      summary {
        &:after {
          display: none;
        }
      }
    }
  }

  .td-logo {
    .logo-component {
      min-width: auto;
      width: 45px;
      @include md {
        width: 60px;
      }
      @include lg {
        width: 90px;
      }
    }
  }
}
</style>

<template lang="pug">
  .host-selection-voucher(:class="{'static-voucher':selectionVoucher && selectionVoucher.IsStaticSelectionVoucher}")
    LoadingSpinnerOverlay(v-if="isLoading")
    .container
      BuyersProtectionBox
    .container(v-if="host")
      HostHeader.py-4(:title="host.Name" :sub-title="host.CityRegion")
        template(v-slot:afterTitle)
          HostFavoriteIcon(:host="host" v-if="host")
      .pb-4
        .voucher-hero.loader
          img.img-fluid.w-100(v-if="selectionVoucher.VoucherImage" :src="selectionVoucher.VoucherImage")
        AnniversaryHostHeader.mt-4
    HostSectionHeader.item-h1.mb-0.position-relative(style="z-index:1") Gutscheindetails
    .container
      .row.position-relative.align-items-stretch
        .col-lg-7.col-xl-8.pb-lg-0.details-column
          template(v-if="!selectionVoucher.IsStaticSelectionVoucher")
            .article-number.txt-td-grey-basic.mt-2(v-if="voucherItem.SystemItemId")
              span.user-select-none Art.-Nr.&nbsp;
              | {{voucherItem.SystemItemId}}
            .headline.item-h1.mt-4.mb-2(v-if="selectionVoucher.Headline") {{selectionVoucher.Headline}}
            .subline.item-h2.mb-4.mt-0.txt-td-grey-basic(v-if="selectionVoucher.Subline") {{selectionVoucher.Subline}}
            .mt-4(v-if="selectionVoucher.ChecklistElements && selectionVoucher.ChecklistElements.length > 0")
              b Folgende Leistungen sind inklusive:
              ul.pl-3
                li(v-for="point in selectionVoucher.ChecklistElements") {{point}}
            .mt-4(v-if="selectionVoucher.Description") {{selectionVoucher.Description}}
          HostItemDetails.mt-2(v-else v-bind="itemDetailAttributes")
        .col-lg-5.col-xl-4.menu-column
          HostItemMenu(:host="host" :custom-nights="customNights" :items="listOfItems" :selected-item-id="selectedItem.id" :is-open="hasOpenMenu" @toggle="hasOpenMenu = !hasOpenMenu" :group-items="selectionVoucher.IsStaticSelectionVoucher" :show-item-footer="false" :show-discount-info="selectionVoucher.IsStaticSelectionVoucher" :show-accommodations="false")
            template(v-slot:customFeature="{item}")
              .feature.d-flex.align-items-center(v-if="!selectionVoucher.IsStaticSelectionVoucher")
                font-awesome-icon.icon.px-2(icon="home")
                .value {{numberOfHosts}} Hotels
              template(v-else-if="selectionVoucher.IsStaticSelectionVoucher && getSimpleItemAccommodation(item)")
                font-awesome-icon.icon(icon="bed")
                .value {{getSimpleItemAccommodation(item)}}
              template(v-else) &nbsp;
    HostSectionHeader.item-h2.mb-0 Übersicht der Hotels
    .container.pt-4
      .hosts.row
        .col-lg-4(v-for="host in containedHosts")
          .mb-4
            HostPreviewBox(:name="host.Name" :city-region="host.CityRegion" :stars="getNumberOfStars(host)" :is-superior="isHostSuperior(host)")
              template(v-slot:gallery)
                PreviewHostGallery(:images="getDisplayableHostImages(host)" v-if="!selectionVoucher.IsStaticSelectionVoucher")
                .thumbnail(:style="`background-image:url('${host.Images[0].RemoteURL}');}`" v-else)
              template(v-slot:footer)
                template(v-if="host.Item")
                  hr.m-0.p-0
                  .text-center.txt-td-grey-basic.py-1
                    .d-inline-flex.align-items-center
                      font-awesome-icon.mr-1(icon="moon")
                      div
                        | {{host.Item.Nights}}&nbsp;
                        template(v-if="host.Item.Nights === 1") Nacht
                        template(v-else) Nächte
            .text-center.pt-2(v-if="selectionVoucher && !selectionVoucher.IsStaticSelectionVoucher")
              button.button.button-primary.button-tdays(@click="showHostInfoModal(host)") Details
    hr.mb-4.mt-0
    HostMap(:hosts="mapHosts")
</template>

<script>
import LoadingSpinnerOverlay from "@/views/components/loading/LoadingSpinnerOverlay.vue";
import BuyersProtectionBox from "@/views/components/partials/buyersProtection/BuyersProtectionBox.vue";
import HostHeader from "@/views/components/host/layout/HostHeader.vue";
import HostFavoriteIcon from "@/views/host/partials/favoriteIcon/HostFavoriteIcon.vue";
import HostSectionHeader from "@/views/components/host/layout/HostSectionHeader.vue";
import HostItemDetails from "@/views/components/host/layout/HostItemDetails.vue";
import TextContentLoader from "@/views/components/partials/contentLoader/TextContentLoader.vue";
import HostItemMenu from "@/views/components/host/layout/HostItemMenu.vue";
import {
  getItemAccommodation,
  getItemCaterings,
  getItemHolidayServices,
  getSimpleItemAccommodation, orderItemsAscendingByPrice
} from "@/lib/helper/items";
import { getNumberOfStars, isHostSuperior, getDisplayableHostImages } from "@/lib/helper/host";
import HostPreviewBox from "@/views/components/hostPreview/partials/HostPreviewBox.vue";
import PreviewHostGallery from "@/views/components/host/gallery/PreviewHostGallery.vue";
import HostMap from "@/views/components/partials/hostMap/HostMap.vue";
import SelectionVoucherItemInfoModal
  from "@/views/components/modals/selectionVoucherItemInfo/SelectionVoucherItemInfoModal.vue";
import { Hosts } from "@/graphql/Hosts.ts";
import { definedObject, objectWithout } from "@/lib/helper/object";
import { parseNumber } from "@/lib/helper/math";
import AnniversaryHostHeader from "@/views/static/anniversary/partials/AnniversaryHostHeader.vue";

export default {
  name: "HostSelectionVoucherView",
  components: {
    AnniversaryHostHeader,
    HostMap,
    PreviewHostGallery,
    HostPreviewBox,
    HostItemMenu,
    TextContentLoader,
    HostItemDetails,
    HostSectionHeader,
    HostFavoriteIcon,
    HostHeader,
    BuyersProtectionBox,
    LoadingSpinnerOverlay
  },
  props: {
    host: {
      type: Object,
      required: true
    },
    itemId: String
  },
  setup() {
    return { getNumberOfStars, isHostSuperior, getSimpleItemAccommodation };
  },
  data() {
    return {
      isLoading: false,
      hasOpenMenu: false
    };
  },
  computed: {
    customNights() {
      if (this.selectionVoucher.IsStaticSelectionVoucher) return undefined;
      const sortedValues = this.containedItems.flatMap((item) => item.Nights).sort((a, b) => a - b);
      const min = sortedValues[0];
      const max = sortedValues[sortedValues.length - 1];
      if (min === max) return min;
      return [min, max];
    },
    listOfItems() {
      if (this.selectionVoucher.IsStaticSelectionVoucher) return orderItemsAscendingByPrice(this.host?.Items) || [];
      return [this.voucherItem];
    },
    selectedItem() {
      return this.listOfItems.find((itm) => itm.id == this.itemId);
    },
    itemDetailAttributes() {
      if (!this.selectedItem) return {};
      return {
        title: this.selectedItem.CustomAttributes?.Custom_Product_Arrangement,
        articleNumber: this.selectedItem.SystemItemId,
        accommodation: getItemAccommodation(this.selectedItem),
        caterings: getItemCaterings(this.selectedItem),
        holidayServices: getItemHolidayServices(this.selectedItem),
        touristServices: this.selectedItem.CustomAttributes?.Custom_Product_TouristServices,
        additionalInfo: this.selectedItem.CustomAttributes?.Custom_Product_ServicesExtra,
        bookability: this.selectedItem.CustomAttributes?.Custom_Product_Bookability
      };
    },
    voucherItem() {
      const [item] = this.host?.Items || [undefined];
      return item;
    },
    selectionVoucher() {
      return this.host?.SelectionVoucher;
    },
    containedItems() {
      if (this.selectionVoucher?.IsStaticSelectionVoucher) {
        if (!this.selectionVoucher?.JsonItems?.length) return [];
        return this.selectionVoucher.JsonItems.map((jsonItem) => {
          return {
            Host: {
              Name: jsonItem.Name,
              CityRegion: jsonItem.City,
              Images: [{ RemoteURL: jsonItem.ImageUrl }],
              Latitude: jsonItem?.Latitude || undefined,
              Longitude: jsonItem?.Longitude || undefined
            }
          };
        });
      }
      return this.selectionVoucher?.Items || [];
    },
    containedHosts() {
      const hosts = this.containedItems.filter((item) => !!item.Host).map((item) => {
        const r = {
          ...definedObject(item.Host)
        };
        if (!this.selectionVoucher.IsStaticSelectionVoucher) {
          r.Item = objectWithout(item, ["Host"]);
        }

        return r;
      });
      return [...hosts].sort((a, b) => {
        const [valueA, valueB] = [a, b].map((host) => {
          const value = this.getNumberOfStars(host);
          return this.isHostSuperior(host) ? value + 1 : value;
        });

        return valueB - valueA;
      });
    },
    mapHosts() {
      return this.containedHosts.map((h) => {
        if (h.Latitude) h.Latitude = parseNumber(h.Latitude);
        if (h.Longitude) h.Longitude = parseNumber(h.Longitude);
        return h;
      }).filter((h) => h.Latitude && h.Longitude);
    },
    numberOfHosts() {
      return this.containedHosts?.length || 0;
    }
  },
  methods: {
    getDisplayableHostImages(host) {
      const images = this.selectionVoucher.IsStaticSelectionVoucher ? host.Images : getDisplayableHostImages(host);
      return images.map((image) => {
        return { url: image.RemoteURL };
      });
    },
    async loadHostDetails(id) {
      this.isLoading = true;
      try {
        return await this.$apollo.query({
          query: Hosts.Queries.Host,
          variables: { id }
        }).then((response) => {
          return response?.data?.host;
        });
      } finally {
        this.isLoading = false;
      }

    },
    async showHostInfoModal(host) {
      const fullHost = await this.loadHostDetails(host.id);
      this.$modal.show(
        SelectionVoucherItemInfoModal,
        {
          item: host.Item,
          host: fullHost
        },
        {
          height: "auto",
          classes: ["rounded-0", "sv-info-modal", "modalMaxHeight"]
        }
      );
    }
  }
};
</script>
<style lang="scss">
.host-selection-voucher {
  &:not(.static-voucher) {
    .host-item-menu-item {
      .prices {
        display: flex;
        align-items: center;
      }
    }
  }
}
</style>
<style lang="scss" scoped>
.voucher-hero {
  aspect-ratio: 2.65 / 1;
}

.static-voucher {
  .voucher-hero {
    aspect-ratio: 2.86 / 1;
  }
}

.hosts {
  .thumbnail {
    aspect-ratio: 4 / 3;
    width: 100%;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
  }
}

.headline,
.subline {
  font-weight: 500;
  line-height: 1.2;
}
</style>

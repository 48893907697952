<template lang="pug">
  .anniversary-popup-modal.bg-td-grey-darker
    .text-right.p-2
      button.no-style.txt-white(@click="closeModal")
        font-awesome-icon.h2(icon="times")
    .px-4.pb-4
      AnniversaryEventHeader
      hr
      .position-relative
        .position-absolute.w-100.h-100(v-if="isLoading" style="background-color:rgba(0,0,0,0.3);z-index:1;")
          .spinner.position-absolute
            LoadingSpinner
        AnniversarySignupSection.txt-white.mt-4(:contest="contest" :shows-share-text="false" @done="closeModal" v-if="contest")
        .spacer(style="height:250px;" v-else)
      .text-center
        router-link.button.button-primary.button-anniversary(:to="{name:'AnniversaryView'}" target="_blank") mehr erfahren
</template>
<script>
import AnniversaryEventHeader from "@/views/static/anniversary/partials/AnniversaryEventHeader.vue";
import { MarketingPromoContest } from "@/graphql/MarketingPromoContest.ts";
import AnniversarySignupSection from "@/views/static/anniversary/partials/AnniversarySignupSection.vue";
import LoadingSpinner from "@/views/components/loading/LoadingSpinner.vue";

export default {
  name: "AnniversaryPopupModal",
  emits: ["close"],
  components: {
    LoadingSpinner,
    AnniversarySignupSection,
    AnniversaryEventHeader
  },
  data() {
    return {
      contest: undefined,
      isLoading: true
    };
  },
  methods: {
    async loadContest() {
      return await this.$apollo.query({
        query: MarketingPromoContest.Queries.AnniversaryModalContest
      }).then((response) => response?.data?.anniversaryContest);
    },
    closeModal() {
      this.$emit("close");
    }
  },
  async mounted() {
    try {
      this.isLoading = true;
      this.contest = await this.loadContest();
    } finally {
      this.isLoading = false;
    }
  }
};
</script>
<style lang="scss">
@import "@/assets/styles/mediaqueries";

.anniversary-popup {
  .signup-headline {
    margin-bottom: 1.5rem;
  }

  .text-xl {
    font-size: 1.5rem !important;
    padding: 1rem !important;
  }

  .year-digit {
    margin: 0 auto;
    max-width: 250px;

    img {
      //min-width: 300px;
      padding: 0 4rem;
    }

    @include md {
      max-width: 250px;
    }
    @include lg {
      max-width: 250px;
    }
    //max-width:
  }

  .large-headline {
    font-size: 35px;
    @include md {
      font-size: 45px
    }
    @include lg {
      font-size: 45px
    }
  }
}
</style>
<style lang="scss" scoped>
@import "@/assets/styles/anniversary-colors";

.spinner {
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.pre-title {
  color: $color-anniversary-light;
}
</style>

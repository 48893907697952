<template lang="pug">
  .anniversary-winners.bg-td-grey-darker
    LoadingSpinnerOverlay(v-if="isLoading")
    header
      .hero
        div(style="padding:10vh 0 5vh 0")
          AnniversaryEventHeader
        div.ribbon-container.position-relative(style="height:25px;margin-bottom:5rem;")
          div.position-absolute.ribbon
            img.w-100.img-fluid(src="https://s3-cloud.td-cdn.de/Images/Promo/30-Jahre-TD/schleife.png")
    section.my-4.container
      template(v-if="prizesByMonth && prizesByMonth.length")
        .month.text-anniversary-light(v-for="entry in prizesByMonth")
          .d-flex.align-items-center.justify-content-between
            .text-left.h1.m-0 Gewinn im {{entry.month}}
            .text-right
              | Dieser Gewinn geht an die Losnummer&nbsp;
              b {{getTicket(entry.prizes[0])}}
          hr.my-1
          .bg-anniversary-basic.txt-td-grey-darker
            DiscountCodeItem.mb-1.txt-td-grey-darker(v-for="prize in entry.prizes" :item="prize.Item")
    footer.sticky-bottom.py-4.text-center.bg-td-grey-darker
      router-link.button.button-primary.button-anniversary.w-100(:to="{name:'AnniversaryView'}" style="max-width:320px")
        .h2.m-0 zum Gewinnspiel
</template>
<script>
import EventBus from "@/event-bus";
import { WebTemplatesEnum } from "@/utils/WebTemplatesEnum.ts";
import AnniversaryEventHeader from "@/views/static/anniversary/partials/AnniversaryEventHeader.vue";
import { MarketingPromoContest } from "@/graphql/MarketingPromoContest.ts";
import AppLoadingSpinner from "@/views/components/loading/AppLoadingSpinner.vue";
import LoadingSpinnerOverlay from "@/views/components/loading/LoadingSpinnerOverlay.vue";
import SmallHostGallery from "@/views/components/host/gallery/SmallHostGallery.vue";
import { getDisplayableHostImages, getDisplayableHostImageUrls, isHostSuperior } from "@/lib/helper/host";
import moment from "moment";
import ResponsiveHostGallery from "@/views/components/host/gallery/ResponsiveHostGallery.vue";
import DiscountCodeItem from "@/views/static/anniversary/partials/DiscountCodeItem.vue";
import { createError } from "@/lib/components/error/createError";
import httpErrorMessages from "@/lib/httpErrorMessages";

export default {
  name: "AnniversaryWinners",
  components: {
    DiscountCodeItem,
    ResponsiveHostGallery,
    SmallHostGallery,
    LoadingSpinnerOverlay,
    AppLoadingSpinner,
    AnniversaryEventHeader
  },
  setup() {
    return { getDisplayableHostImageUrls, isHostSuperior };
  },
  data() {
    return {
      isLoading: false,
      prizes: []
    };
  },
  computed: {
    prizesByMonth() {
      const results = [];
      const months = this.prizes.map((prize) => this.getMonthName(prize));
      months.forEach((month) => {
        results.push({ month, prizes: [] });
      });
      this.prizes.forEach((prize) => {
        const month = this.getMonthName(prize);
        results.find((entry) => entry.month === month).prizes.push(prize);
      });
      return results;
    }
  },
  methods: {
    getTicket(prize) {
      const [participant] = prize.MarketingPromoParticipant.MarketingPromoContestParticipants;
      return participant.Ticket;
    },
    getItemUrl(item) {
      return `/gastgeber/${item.Host.Url}#${item.id}`;
    },
    getImagePaths(host) {
      return getDisplayableHostImages(host).map((img) => img?.RemoteURL);
    },
    getMonthName(prize) {
      const m = moment(prize.PrizeDrawnAt).toDate().getMonth() + 1;
      switch (m) {
        case 1:
          return "Januar";
        case 2:
          return "Februar";
        case 3:
          return "März";
        case 4:
          return "April";
        case 5:
          return "Mai";
        case 6:
          return "Juni";
        case 7:
          return "Juli";
        case 8:
          return "August";
        case 9:
          return "September";
        case 10:
          return "Oktober";
        case 11:
          return "November";
        case 12:
          return "Dezember";
      }
    },
    async loadPrizes() {
      return await this.$apollo.query({ query: MarketingPromoContest.Queries.AnniversaryWinnerPrizes }).then((response) => response?.data?.anniversaryContestWinnerPrizes);
    }
  },
  async mounted() {
    if (moment().isBefore("2025-02-28 09:00:00")) throw createError(httpErrorMessages["404"]);
    document.querySelector("body").classList.add("anniversary-page");
    EventBus.$emit("changeWebTemplateType", WebTemplatesEnum.Anniversary);
    this.isLoading = true;
    try {
      this.prizes = await this.loadPrizes();
    } finally {
      this.isLoading = false;
    }
  },
  unmounted() {
    document.querySelector("body").classList.remove("anniversary-page");
    EventBus.$emit("changeWebTemplateType", WebTemplatesEnum.Default);
  },
  destroyed() {
    document.querySelector("body").classList.remove("anniversary-page");
    document.querySelector("body").classList.remove("no-scroll");
    EventBus.$emit("changeWebTemplateType", WebTemplatesEnum.Default);
  },
  metaInfo() {
    const description = "WIR FEIERN 30 JAHRE TOURIDAT | Melden Sie sich jetzt zum wunderbaren touriDat Jubiläumsgewinnspiel 2025 an, nutzen sie jeden Monat die Gewinnchance auf eine fantastische Reise und profitieren sie von grandiosen Rabatten.";
    const title = "Wir feiern 30 Jahre touriDat | touriDat Jubiläum 2025 - Gewinnerübersicht";
    return {
      title,
      meta: [
        {
          vmid: "description",
          name: "description",
          content: description
        },
        {
          vmid: "og:title",
          name: "og:title",
          content: `${title} | touriDat.com`
        },
        {
          vmid: "og:description",
          name: "og:description",
          content: description
        },
        {
          vmid: "keywords",
          name: "keywords",
          content:
            "jubiläum, gewinnspiel, touridat, 30 jahre, reisegutschein"
        },
        {
          vmid: "og:image",
          name: "og:image",
          content:
            "https://s3-cloud.td-cdn.de/Images/Promo/30-Jahre-TD/anniversary-2025-og-image.jpg"
        }
      ],
      link: [
        {
          vmid: "canonical",
          rel: "canonical",
          href: this.$touriConfig.server.host + "/30-jahre-jubilaeum/gewinner"
        }
      ]
    };
  }
};
</script>
<style scoped lang="scss">
.anniversary-winners {
  hr {
    background-color: #c8b58c;
  }
}
</style>
